<template>
    <div class="wrapper">
        <div v-if="loading">
            Loading weather...
        </div>
        <div v-else>
          <div class="row">
            <div class="col-6" style="white-space: nowrap;">
              <h6 v-if="weather.status!=='unavailable'" style="overflow: hidden; text-overflow: ellipsis; margin-bottom:0;">{{weather.forecast}}</h6>
              <span v-if="weather.status==='forecast'" style="font-size:12px;"> Forecasted</span>
            </div>
            <div class="col-6" v-if="weather.status!=='unavailable'">
              <h6 style="text-align:right">

                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" style="fill:white;height:13px;padding-right:2px;"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M160 64c-26.5 0-48 21.5-48 48V276.5c0 17.3-7.1 31.9-15.3 42.5C86.2 332.6 80 349.5 80 368c0 44.2 35.8 80 80 80s80-35.8 80-80c0-18.5-6.2-35.4-16.7-48.9c-8.2-10.6-15.3-25.2-15.3-42.5V112c0-26.5-21.5-48-48-48zM48 112C48 50.2 98.1 0 160 0s112 50.1 112 112V276.5c0 .1 .1 .3 .2 .6c.2 .6 .8 1.6 1.7 2.8c18.9 24.4 30.1 55 30.1 88.1c0 79.5-64.5 144-144 144S16 447.5 16 368c0-33.2 11.2-63.8 30.1-88.1c.9-1.2 1.5-2.2 1.7-2.8c.1-.3 .2-.5 .2-.6V112zM208 368c0 26.5-21.5 48-48 48s-48-21.5-48-48c0-20.9 13.4-38.7 32-45.3V272c0-8.8 7.2-16 16-16s16 7.2 16 16v50.7c18.6 6.6 32 24.4 32 45.3z"/></svg>
                  {{weather.temperature}}{{weather.unit}} / 
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style="fill:white;height:13px;padding-right:2px;"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M288 32c0 17.7 14.3 32 32 32h32c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32 14.3-32 32s14.3 32 32 32H352c53 0 96-43 96-96s-43-96-96-96H320c-17.7 0-32 14.3-32 32zm64 352c0 17.7 14.3 32 32 32h32c53 0 96-43 96-96s-43-96-96-96H32c-17.7 0-32 14.3-32 32s14.3 32 32 32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H384c-17.7 0-32 14.3-32 32zM128 512h32c53 0 96-43 96-96s-43-96-96-96H32c-17.7 0-32 14.3-32 32s14.3 32 32 32H160c17.7 0 32 14.3 32 32s-14.3 32-32 32H128c-17.7 0-32 14.3-32 32s14.3 32 32 32z"/></svg>
                  <!-- {{weather.winddirection}} at {{weather.windspeed}} -->
                  {{weather.windspeed}}

              </h6>
            </div>
          </div>
        </div> 
        <div>
          <p style="margin-bottom:0;">{{challenge.away.name}} {{challenge.away.mascot}} at {{challenge.home.name}} {{challenge.home.mascot}}</p>
          <p style="margin-bottom:0;">{{challenge.home.stadium.name}}, {{challenge.home.stadium.city}}, {{challenge.home.stadium.state}}</p>
          <p style="font-size: 12px;color: gray;">
            <i>{{challenge.home.stadium.capacity}} Stadium Capacity</i>
          </p>
        </div>   
    </div>
</template>

<script>
import { api } from "@/utils/api";

export default {
  name: "Weather",
  props: {
    cid: null,
    challenge: null
  },
  data() {
    return {
        weather: null,
        loading: true
    };
  },
  created() {
    let self = this;
    api(
      `query {weather(cid:"${this.$route.params.id}")}`
    ).then((data) => {
      self.weather = JSON.parse(data.weather);
      self.loading = false;
    });
  },
};
</script>

<style scoped>
.wrapper {
  border-radius: 4px;
  /* background-color: #242424; */
  padding: 10px;
  border: 1px solid #303030;
  /* box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22); */
  margin-bottom: 10px;
}
</style>