<template>
  <div v-if="challenge">
    <Faceoff :challenge="challenge" />  
    <!-- Weather and stadium info -->
    <weather :cid="this.$route.params.id" :challenge="challenge" />

    <div v-if="needtobet">
      <Bet :challenge="challenge" @clicked="onClickChild" />
    </div>
    <p>{{message}}</p>

    <Bets v-if="!needtobet" :challenge="challenge" />
  </div>
</template>

<script>
import { api } from "@/utils/api";

import Header from "@/components/Header";
import Faceoff from "@/components/NewFaceoff";
import Weather from "@/components/Weather";
import Bet from "@/components/Bet";
import Bets from "@/components/NewBets";
export default {
  name: "Challenge",
  components: {
    Header,
    Faceoff,
    Bet,
    Bets,
    Weather
  },
  data() {
    return {
      challenge: "",
      needtobet: false,
      message: "",
    };
  },
  created() {
    let self = this;
    api(
      `query { challenge(id:"${this.$route.params.id}") { 
        id
        spread
        totalpoints
        line {
          id
          name
        } 
        live
        lineFOdds
        lineAOdds
        spread
        spreadFOdds
        spreadAOdds
        totalpoints
        totalFOdds
        totalAOdds
        freePlay
        date
        home {
          id
          name
          abbrev
          logo
          mascot
          stadium {
            name
            city
            state
            capacity
            latitude
            longitude
            } 
          }
          away {
            id
            name
            abbrev
            logo
            mascot
          }
          results {
            winner {
              id
              name
            }
            spread {
              id
              name
            }
            homePoints
            awayPoints
            totalpoints
          }
          bets {
            id
          }
        }
        needtobet(cid:"${this.$route.params.id}") 
      }`
    ).then((data) => {
      self.challenge = data.challenge;
      self.needtobet = data.needtobet;
    });
  },
  methods: {
    onClickChild(value) {
      // If this gets hit we need to load betting data
      this.needtobet = false;
    },
  },
};
</script>

<style scoped>
</style>